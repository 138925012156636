//  Simple card with an icon: 
// 
//  <div class="crd [text-white][...]">
//     <a href="">
//         <div class="crd-body crd-body-icon[-r][-l] crd-body[-bnrcour1][...]">
//             <h3 class="crd-h">Title</h3>
//             <div class="crd-icon crd-icon[-arrow][...]"></div>
//         </div>
//     </a>
// </div>

$crd-standard-rdius: .5em;
$crd-body-min-height: 5em;
$crd-body-padding: 1em;
$crd-icon-width: 2em;

.crd {
    width: 100%;

    border-radius: $crd-standard-rdius;
    box-shadow: 0 3px 1px -2px #ccc, 0 2px 2px 0 #e5e5e5, 0 1px 5px 0 #e5e5e5;

    overflow: hidden;

    &-body {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        min-height: $crd-body-min-height;
        padding: $crd-body-padding;

        border-radius: $crd-standard-rdius;
        background-color: $primary;

        &-icon-r { padding-right: $crd-icon-width + 2 * $crd-body-padding; } 
        &-icon-l { padding-left: $crd-icon-width + 2 * $crd-body-padding; } 

        // As a banner for course-1
        &-bnrcour1 {

            // background-color: $color-course-1;
            // background: linear-gradient(90deg, $success 0%, $color-course-1 100%);
            // Experiment:
            background-color: #d4226d;
            background: linear-gradient(90deg, #d4226d 0%, #fdd603 100%);
            &:before {
                display: block;
                position: absolute;
                top: -$crd-body-padding;
                left: -$crd-body-padding;
                width: 150%;
                height: 150%;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23ffffff' fill-opacity='0.15' fill-rule='evenodd'/%3E%3C/svg%3E");
                content: '';
            }

        }

        // As a banner for course-2
        &-bnrcour2 {
            // background-color: $color-course-2;
            // background: linear-gradient(90deg, $success 0%, $color-course-2 100%);
            // Experiment:
            background-color: #00bb00;
            background: linear-gradient(90deg, #00bb00  0%, #46E1E8 100%);
            &:before {
                display: block;
                position: absolute;
                top: -$crd-body-padding;
                left: -$crd-body-padding;
                width: 150%;
                height: 150%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                content: '';
            }
        
        }

    }
    
    &-icon {
        position: absolute;
        top: 0;
        .crd-body-icon-r & { right: $crd-body-padding; }
        .crd-body-icon-l & { left: $crd-body-padding;  }
        
        height: 100%;
        width: $crd-icon-width;
        
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;

        .text-white &-arrow {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' color='white'%3E%3Cpolygon fill='currentColor' points='2.22 1.73 0 3.95 4.05 8 0 12.05 2.22 14.27 8.48 8 2.22 1.73'/%3E%3Cpolygon fill='currentColor' points='9.73 1.73 7.52 3.95 11.57 8 7.52 12.05 9.73 14.27 16 8 9.73 1.73'/%3E%3C/svg%3E");

        }

    }

    a {
        display: block;

        &:hover {
            text-decoration: none;
            opacity: .8;       
        }

    }

    &.text-white a {
        color: white;

    }

    &-h {
        font-size: 1.25em;
        font-weight: $font-weight-normal;

    }


    // todo ↓

    // &-primary {}

    // &-secondary {}

    // &-footer {}

}