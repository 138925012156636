.card-result {
    @extend .mb-4;
    @extend .py-2;
    @extend .px-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: $font-size-lg;
    border-radius: $border-radius;
    color: white; 
    // background: /*linear-gradient(to top, rgba(255, 255, 255, .15), rgba(0, 0, 0, .25)),*/ 
    //               linear-gradient(to left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
    &-title {
        min-width: 240px;
    }
    &-value {
        @extend .fs-6;
        @extend .pb-1;
        line-height: 2.3rem;
    }
    &-desc {
        @extend .lead;
        @extend .mb-1;
    }
    &-btn {
        @extend .shadow;
        @extend .my-2;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: auto;
    }
    &-content {
        max-width: 700px;
    }
    &-score {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 0.5;
    }
    &-score-text {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.25;
    }
}