@import (reference) "custom-standards.less";
.input-filter {
    &:focus {
        border-color: @color-filter !important;
        box-shadow: 0 0 0 .2rem rgba(red(@color-filter), green(@color-filter), blue(@color-filter), .25) !important;
    }
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    &-filtered {
        border-color: @color-filter;
        border-radius: 0 !important;
    }
}

.input-group-filtered {
    background-color: @color-filter;
    border-color: @color-filter;
    color: #fff;
}

.filtered {
    &.expl-tree__item-desc,
    &.expl-tree__item-desc[href] {
        color: @color-filter  !important;
        // &:hover {
        //     // color: lighten(@color-filter, 20%) !important;
        //     // text-decoration: underline solid lighten(@color-filter, 20%) !important;
        // }
    }
    &:not(.filtered-disabled):before {
        @current-color: escape(@color-filter);
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='@{current-color}' d='M5.94,8.7A2.06,2.06,0,1,0,8,6.6,2.08,2.08,0,0,0,5.94,8.7m7.56,4.61V5.2L9.37,1H3.87A1.39,1.39,0,0,0,2.5,2.4V13.6A1.39,1.39,0,0,0,3.87,15h8.25a1.31,1.31,0,0,0,.82-.28l-3-3.1A3.51,3.51,0,1,1,11.44,8.7a3.67,3.67,0,0,1-.57,1.93Z'/%3E%3C/svg%3E");
        display: inline-block;
        width: 1em;
        font-size: inherit;
        vertical-align: middle;
        line-height: inherit;
        margin: 0 .7em 0 -3px;
    }
    &-disabled {
        &.expl-tree__item-desc,
        &.expl-tree__item-desc[href] {
            color: gray !important;
            // &:hover {
            //     // color: lighten(gray, 20%) !important;
            //     // text-decoration: underline solid lighten(gray, 20%) !important;
            // }
        }
    }
}