@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-regular-webfont.eot');
    src: url('fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('fonts/roboto-regular-webfont.woff') format('woff'),
         url('fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: $font-weight-normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-italic-webfont.eot');
    src: url('fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('fonts/roboto-italic-webfont.woff') format('woff'),
         url('fonts/roboto-italic-webfont.ttf') format('truetype'),
         url('fonts/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: $font-weight-normal;
    font-style: italic;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-bold-webfont.eot');
    src: url('fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('fonts/roboto-bold-webfont.woff') format('woff'),
         url('fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: $font-weight-bold;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-bolditalic-webfont.eot');
    src: url('fonts/roboto-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/roboto-bolditalic-webfont.woff') format('woff'),
         url('fonts/roboto-bolditalic-webfont.ttf') format('truetype'),
         url('fonts/roboto-bolditalic-webfont.svg#robotobold_italic') format('svg');
    font-weight: $font-weight-bold;
    font-style: italic;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-light-webfont.eot');
    src: url('fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-light-webfont.woff2') format('woff2'),
         url('fonts/roboto-light-webfont.woff') format('woff'),
         url('fonts/roboto-light-webfont.ttf') format('truetype'),
         url('fonts/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: $font-weight-light;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-lightitalic-webfont.eot');
    src: url('fonts/roboto-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/roboto-lightitalic-webfont.woff') format('woff'),
         url('fonts/roboto-lightitalic-webfont.ttf') format('truetype'),
         url('fonts/roboto-lightitalic-webfont.svg#robotolight_italic') format('svg');
    font-weight: $font-weight-light;
    font-style: italic;
    font-display: auto;
}

