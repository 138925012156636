@import (reference) "custom-standards.less";
// Local variables
// /Local variables
.form-sign {
    width: 100%;
    max-width: 330px; //padding: 15px;
    margin: 0 auto;
    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .input-group {
        .input-group-text {
            background-color: #f8f9fa;

        }
    }
}
