//
// Simple widget
// Used in test and exercise results
//

$widget2-types: (
    'eff': $primary,
    'wrong': $color-wrong,
    'right': $color-correct
);

.widget2 {
    text-align: center;
    &-title {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: $font-weight-bold;
        color: inherit;     
    }
    &-value {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: 2.3rem;
        line-height: 1.1;
        font-weight: $font-weight-bold;;
        color: inherit;
    }
    &-sub {
        font-size: 1.8rem;
    }
}

@each $type, $value in $widget2-types {
    .widget2-#{$type} {
        color: $value;
    }
}
