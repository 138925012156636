.container {
    &-expl {
        @include media-breakpoint-up(md) {
            @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( -moz-min-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2), only screen and ( min-device-pixel-ratio: 2) {
                max-width: 520px;
            }
        }
        @include media-breakpoint-up(lg) {
            max-width: 900px;
            @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( -moz-min-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2), only screen and ( min-device-pixel-ratio: 2) {
                max-width: 720px;
            }
        }
    }
}