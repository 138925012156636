.lb-fadeinleft {
    animation-name: lb-fadeinleft;
    animation-duration: 1s;
}

@keyframes lb-fadeinleft {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}