.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    list-style: none;
    &__item {
        a {
            opacity: .6;
            color: currentColor;
            text-decoration: underline;
            &:hover {
                opacity: .4;
                color: currentColor;
            }
        }
        &:after {
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            content: "/";
        }
        &:last-child:after {
            content: "";
        }
    }
}

