.label {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    display: inline-block;
    font-weight: $font-weight-normal;
    line-height: 1.3;
    border-radius: 6.1875rem;
    transition: 0.2s ease-in-out;
}

@each $name, $color in $theme-colors {
    .label-#{$name} {
        color: $color;
        background-color: rgba($color, .1);
    }
    .label-inverted-#{$name} {
        color: color-yiq($color);
        background-color: $color;
    } 
}