@mixin states($color:black) {
    will-change: transform, opacity;
    &:before {
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        content: '';
        opacity: 0;
        background-color: $color;
    }
    &:hover:before,
    &:focus:before {
        opacity: .08;
        transition: opacity 75ms linear;
    }
    &:active:before {
        opacity: .16;
        transition: opacity .15s linear;
    }
}

@mixin states-static($color:black) {
    &:before {
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        content: '';
        opacity: .5;
        background-color: $color;
    }
}