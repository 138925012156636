.a-underline a,
a.a-underline {
    white-space: nowrap;
    color: inherit;
    position: relative;
    text-decoration: none;
    &:after {
        background-color: $color-1;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        top: 100%;
        transition: all .2s ease;
        width: 100%;
        opacity: .5;
    }
    &:hover {
        text-decoration: none;
        &:after {
            opacity: 1;
        }
    }
}

a.a-pu, // Primary color, underlined
.a-pu a:not(.btn)
 {
    color: $primary;
    text-decoration: underline;
    &:hover {
        color: darken($primary, 15%);
    }
}


a.a-wu,  // White color, underlined
.a-wu a:not(.btn)
 {
    color: white;
    text-decoration: underline;
    &:hover {
        opacity: .6;
    }
}