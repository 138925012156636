.e-card-list {
    margin-bottom: 16px;
    padding-top: 16px;
    &__wrapper {
        padding: 0;
        border-top: 1px solid #dadce0;
        @extend .container-fluid;
    }
    &__primary {
        margin: 0 0 24px;
        position: relative;
        // @include media-breakpoint-up(lg) {
        //     padding-right: 32px + 15px;
        // }
    }
    &__topic {
        margin-bottom: 8px;
        margin-top: 24px;
        font-size: 25px;
        font-weight: $font-weight-normal;
        .unit_disabled & {
            opacity: .38;
        }
    }
}