.animated {
    animation-duration: 1s; 
    // animation-duration: 1.5s;
    animation-fill-mode: both;
}

.animated.slow {
    animation-duration: 2s;
}

@media (prefers-reduced-motion) {
    .animated {
        animation: unset !important;
        transition: none !important;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0; 
        //transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

// .animated.delay-025s {
//     animation-delay: .25s;
// }
// .animated.delay-05s {
//     animation-delay: .5s;
// }
// .animated.delay-1s {
//     animation-delay: 1s;
// }