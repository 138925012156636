@import "utilities/_font-size";
@import "utilities/_animate";   // for WOW.js
@import "utilities/_animation"; // for custom animation
@import "utilities/_sizing";
@import "utilities/align";

.u-vertical-divider-md {
    @include media-breakpoint-up(md) {
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-right: 1px solid #e7eaf3;
            content: "";
        }
    }
}

.u-vertical-divider-lg {
    @include media-breakpoint-up(lg) {
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-right: 1px solid #e7eaf3;
            content: "";
        }
    }
}

.text-wrap {
    white-space: normal;
}

.overflowx-hidden {
    overflow-x: hidden !important;
}