.unit {
    margin-bottom: 48px;
    padding-top: 48px;
    &__primary {
        margin: 0 0 32px;
        position: relative;
        @include media-breakpoint-up(lg) {
            padding-right: 32px + 15px;
        }
        &[aria-expanded="true"] {
            .unit__toggle-button {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__wrapper {
        padding: 0;
        border-top: 1px solid #dadce0;
        @extend .container-fluid;
    }
    &_disabled {
        // used as parent
    }
    &__toggle-button {
        position: absolute;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 1px 2px #0000004d;
        cursor: pointer;
        transition: box-shadow 365ms cubic-bezier(.4, 0, .2, 1);
        align-items: center;
        display: flex;
        justify-content: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        img {
            user-select: none;
            width: 16px;
            height: 16px;
            transition: transform 365ms cubic-bezier(.4, 0, .2, 1);
        }
        &:hover {
            box-shadow: 0 2px 4px 0 #0000001a, 0 4px 5px 0 #0000001a, 0 1px 10px 0 #0000001a;
        }
        @include media-breakpoint-up(lg) {
            width: 32px;
            height: 32px;
            img {
                width: 12px;
                height: 12px;
            }
        }
    }
    &__title {
        font-size: 32px;
        font-weight: $font-weight-normal;
        .unit_disabled & {
            opacity: .38;
        }
    }
    &__secondary {
        max-width: 760px;
        margin-bottom: 0;
        .unit_disabled & {
            opacity: .38;
        }
        p {
            margin: 0 0 16px;
            font-size: 16px;
            color: $secondary;
        }
    }
    &__content {
        // nothing yet
    }
    &__topic {
        margin-bottom: 8px;
        font-size: 25px;
        font-weight: $font-weight-normal;
        &:not(:first-child) {
            margin-top: 24px;
        }
        .unit_disabled & {
            opacity: .38;
        }
    } 
    // &__topic-desc {
    //     margin: 0 0 16px;
    //     font-size: 16px;
    //     color: $secondary;
    //     .unit_disabled & {
    //         opacity: .38;
    //     }
    // }
}

