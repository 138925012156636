.statbar {
    position: -webkit-sticky;
    position: -ms-sticky;
    position: sticky;
    
    margin-top: -$statbar-height;
    height: $statbar-height;
    bottom: 0px;
    overflow: hidden;
    z-index: 1;
   
    background-color: rgb(218, 216, 216);
    background-color: $color-1;
      
    &-wrapper {
        position: absolute;
        min-width: $body-minwidth;
        top: 0; bottom: 0; left: 0; right: 0;
        z-index: inherit;
        &:before {
            content: '';
            display: block;
            height: 100%;
            @each $breakpoint in map-keys($grid-breakpoints) {
                @include media-breakpoint-up($breakpoint) {
                    margin-bottom: -1 * ( map-get($footer-height, $breakpoint) + $statbar-height) !important;
                }
            }
        }
    } 
}