.score-chart {
  &-container {
    display: flex;
    @extend .mb-2;
    font-size: .85rem;
  }
  &-category {
    width: 3.1em;
    text-align: right;
    margin-right: 1em;
  }
  &-bar {
    flex-grow: 1;
    margin-right: 3em;
  }
  &-progress-bar {
    @extend .bg-gradient-primary;
    position: relative;
    text-align: right;
    transition: width 2s, background .2s;
    -webkit-transform: translate3d(0,0,0);
    clear: both;
    width: 0;
    height: 100%;
    border-radius: .25rem;
  }
  &-count {
    display: inline-block;
    position: absolute;
    font-weight: 700;
    margin-left: 0.4em;
  }
}