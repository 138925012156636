.table {
    &-comparison {
        margin-bottom: 0 !important;
        &-wrapper {
            padding: 1.875rem 1.875rem 1.25rem;
            border: 1px solid #D6DEE6;
            border-radius: .25rem;
        }
        &__header {
            padding: 0 0 .7rem 0 !important;
            margin: 0 !important;
            border: none !important;
            &_value {
                text-align: center;
                border: none !important;
            }
            &_text {
                display: none;
                @include md {
                    display: table-cell;
                }
            }
            &_free {
                display: none;
                @include md {
                    display: table-cell;
                }
            }
            &_premium {
                text-align: left;
                @include md {
                    text-align: center;
                }
            }
        }
        &__text {
            padding: 1rem 0 !important;
        }
        &__value {
            text-align: center;
            // vertical-align: middle !important;
            .r-icon {
                vertical-align: bottom !important;
            }
            padding: 1rem 0 !important;
            &_free {
                display: none;
                @include md {
                    display: table-cell;
                }
            }
        }
    }
}