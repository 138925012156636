@import (reference) "custom-standards.less";
.card {
    &_course {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 0px !important;
        border: none;
        .lg( {
            border: 1px solid rgba(0, 0, 0, .125);
            margin-bottom: 30px !important;
        }
        );
    }
    &_sm {
        border: none;
        border-radius: unset;
        margin-left: -15px;
        margin-right: -15px;
        .sm( {
            margin-left: 0;
            margin-right: 0;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, .125);
        }
        );
    }
    &_lg{
        border: none;
        border-radius: unset;
        margin-left: -15px;
        margin-right: -15px;
        .lg( {
            margin-left: 0;
            margin-right: 0;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, .125);
        }
        );
    }
    &_expl {
        border-top: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    &-header {
        &_course {
            padding-left: 15px;
            padding-right: 15px;
            background-color: @color-1;
            color: white;
            border-radius: 0px !important;
            padding-top: 3rem;
            padding-bottom: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .sm( {
                flex-direction: row;
                align-items: center;
                justify-content: space-between
            }
            );
            .md( {
                padding-left: 7rem !important;
                padding-right: 7rem !important;
            }
            );
            .lg( {
                border-radius: 3px 3px 0 0 !important;
            }
            );
            .card_disabled & {
                background-color: #BDBDBD;
            }
        }
    }
    &-body {
        &_course {
            padding-left: 15px;
            padding-right: 15px;
            .md( {
                padding-left: 7rem !important;
                padding-right: 7rem !important;
            }
            );
        }
    }
    h2 {
        &.course {
            font-size: 2rem;
            font-weight: bold;
        }
    }
    h3 {
        &.course {
            text-align: left;
            margin-bottom: 30px;
            margin-top: 10px;
            padding: 0 15px;
            width: 100%;
            font-size: 1.4rem;
            line-height: 1.6rem;
            .sm( {
                font-size: 1.6rem;
                line-height: 1.8rem;
            }
            );
            .md( {
                font-size: 1.8rem;
                line-height: 2rem;
            }
            );
        }
    }
}