@import (reference) "custom-standards.less";
// Local variables
@color-jt-background: @color-1;
@box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
// /Local variables

.jumbotron {
    &_course {
        background-color: @color-jt-background;
        //box-shadow: @box-shadow;
        margin-bottom: 0;
        position: relative;
        .lg({
            margin-bottom: 2rem;
        });
    }
    &-widget {
        min-height: 120px;
        &_course {
            //nothing yet
        }
    }

    h1 {
        &.course {
            color: white;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}