@import (reference) "custom-standards.less";
// Local variables
// /Local variables
.l-select {
    color: white;

    .select2 {
        margin-left: 8px;
        margin-bottom: 0.5rem;
        height: 24px;
    }
    .select2-container {
        &.select2-container--default {
            .selection {
                text-align: left;
                .select2-selection--single {
                    background-color: transparent;
                    border-radius: 0;
                    border-width: 0 0 1px 0;
                    border-color: currentColor;
                    outline: none; /* To disable orange border on Android*/
                    .select2-selection__rendered {
                        color: inherit;
                        padding-left: 0;
                    }
                    .select2-selection__arrow {
                        width: 9px;
                        b {
                            border-top-color: currentColor;
                        }
                    }
                }
            }
            &.select2-container--open {
                .selection {
                    .select2-selection--single {
                        .select2-selection__arrow b {
                            border-bottom-color: currentColor;
                        }
                    }
                }
                .select2-dropdown--below {
                    border-radius: 4px; 
                    border: 1px solid @color-1  !important;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                }
            }
            .select2-dropdown {
                margin-top: 10px;
                color: black;
                text-align: left;
            }
            &.select2-container--default {
                .select2-results__option {
                    &[aria-selected="true"] {
                        background-color: transparent;
                    }
                    &--highlighted[aria-selected] {
                        background-color: @color-1;
                    }
                }
            }
        }
    }
}
