.hero {
    &-top {
        @include media-breakpoint-up(lg) { 
            margin-top: -58px;
            min-height: 100vh;
        }
        &-img {
            @include media-breakpoint-up(lg) { 
                position: absolute;
                top: 0;
                right: 0;
                min-height: 100vh;
            }
        }
    }
    &-pros {
        &-img {
            width: 100%;
            max-width: 450px; 
        }
    }
}