// Font weight (short)
//
.fw-l { @extend .font-weight-light; }
.fw-n { @extend .font-weight-normal; }
.fw-b { @extend .font-weight-bold; }

//
// Additional display values
//
.display-5 {
    font-size: $display5-size;
    font-weight: $display5-weight;
    line-height: $display-line-height;
}

.display-6 {
    font-size: $display6-size;
    font-weight: $display6-weight;
    line-height: $display-line-height;
}

.display-7 {
    font-size: $display7-size;
    font-weight: $display7-weight;
    line-height: $display-line-height;
}



//
// Particular headings
//
.h-main {
    @extend .fw-l;
    line-height: $display-line-height;
    font-size: $display5-size;
    @include lg {
        font-size: $display4-size;
    }
}

.h-block {
    @extend .fw-l;
    font-size: $display5-size;
    line-height: $display-line-height;
    @include lg {
        font-size: $display4-size;
    }
    @include xl {
        font-size: $display3-size;
    }
}

.h-section {
    @extend .fw-l;
    font-size: $display6-size;
    line-height: $display-line-height;
    @include lg {
        font-size: $display5-size;
    }
}

.h-paragraph {
    @extend .fw-l;
    font-size: $display7-size;
    line-height: $display-line-height;
}