$c-card-p: ($grid-gutter-width / 2);

$c-card-types: ( 
    "featured": ( 
        title-line-height: 1.2em,
        title-height: 1.2em * 3 // three lines
    ),
    "related": (
        color: black,
        background-color: $warning
    )
);

@mixin c-card-get-value($label, $property, $value, $has-no-ancestor) {
    @if ($has-no-ancestor) {
        &_#{$label} {
            #{$property}: $value;
        }
    }
    @else {
        .c-card_#{$label} & {
            #{$property}: $value;
        }
    }
}

@mixin c-card-add-property($property, $pseudoname: $property, $property-map: $c-card-types, $has-no-ancestor: false) {
    @each $label, $map in $property-map {
        @include c-card-get-value($label, $property, map-get($map, #{$pseudoname}), $has-no-ancestor)
    }
}

.c-card {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-right: auto;
    margin-left: auto;
    color: white;
    background-color: $primary;
    @include c-card-add-property('color', $has-no-ancestor: true);
    @include c-card-add-property('background-color', $has-no-ancestor: true);
    &__primary-action {
        @include states;
        position: relative;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        &:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }
        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
    &__primary {
        padding: $c-card-p; 
    }
    &__title {
        margin: 0;
        font-size: 1.25rem; font-weight: $font-weight-normal; letter-spacing: .0125em; 
        overflow: hidden;
        height: 2em;
        @include c-card-add-property('height', 'title-height');
        line-height: 2em;
        @include c-card-add-property('line-height', 'title-line-height');
    }
    &__sub {
        white-space: nowrap;
        opacity: .6;
        overflow: hidden;
        margin: 5px 0 0 0;
        font-size: .875rem;
        line-height: 1.375rem;
        font-weight: $font-weight-normal;
    }
    &__secondary {
        padding: 0 $c-card-p $c-card-p/2 $c-card-p;
        opacity: .6;
        font-size: .875rem;
        letter-spacing: .01786em;
    }
    &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 50px;
        padding: $grid-gutter-width/4;
    }
    &__action-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        overflow: hidden;
        &_wide {
            width: 100%;
            justify-content: space-between;
        }
    }
    &__action-link {
        text-decoration: none;
        color: inherit;
        height: 36px;
        padding: 0 $grid-gutter-width/4;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        white-space: nowrap;
        word-wrap: none;
        &:not(:last-child) {
            margin-right: $grid-gutter-width/4;
        }
        &:focus,
        &:hover {
            text-decoration: none;
            color: inherit;
        }
        text-transform: uppercase;
        font-size: .875rem;
        line-height: 2.25rem;
        font-weight: $font-weight-bold;
        letter-spacing: .05em;
        @include states;
    }
}