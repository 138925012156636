@import (reference) "custom-standards.less";
// Local variables
@color-googleplus: #dd4b39;
@color-facebook: #3b5998;
// /Local variables
.sign {
    max-width: 390px;
    margin: 0 auto;
    &__sn {
        &:hover {
            text-decoration: none;
            opacity: .8;
        }
        svg {
            width: 55px;
        }
        &_gp {
            path {
                fill: @color-googleplus;
            }
        }
        &_fb {
            path {
                fill: @color-facebook;
            }
        }
    }
}

.a-white {
    color: white !important;
    &:hover {
        // color: darken(white, 15%) !important;
        opacity: .75;
    }
}