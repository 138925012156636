$e-card-p: $grid-gutter-width / 2;
$e-card-label-border-width: 1px;
$e-card-label-width: 46px;  //former: 56px;
$e-card-icon-size: 28px;    //former $e-card-label-width/2;
$e-card-icon-padding: (46px - $e-card-icon-size) / 2 - $e-card-label-border-width;  // former $e-card-label-width/4 - 1;  ' -1 ' bacause of the border  


.e-card {
    border-radius: 24px 8px;
    transition: box-shadow 135ms cubic-bezier(.4, 0, .2, 1), width 235ms cubic-bezier(.4, 0, .2, 1);
    // box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #0000001a, 0 1px 5px 0 #0000001a;
    box-shadow: 0 3px 1px -2px #cccccc, 0 2px 2px 0 #e5e5e5, 0 1px 5px 0 #e5e5e5;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 350px;   
    width: 100%;
    background-color: white;
    margin: 0 auto;
    &_disabled {
        opacity: .38;
    }
    &__wrapper {
        padding-top: $e-card-p;
        padding-bottom: $e-card-p;
        display: inline-block;
        width: 100%;
    }
    &__primary-action {
        border-radius: 24px 8px;
        &:hover {
            text-decoration: none;
            cursor: auto;
        }
        :not(.e-card_disabled)>&:hover,
        :not(.e-card_disabled)>&:focus {
            opacity: .68;
            cursor: pointer;
        }
        .e-card_course-1 & {
            color: white !important;
            background-color: $color-course-1;
        }
        .e-card_course-2 & {
            color: white !important;
            background-color: $color-course-2;
        }
        .e-card_course-4 & {
            color: white !important;
            background-color: $color-course-4;
        }
        .e-card_theory & {
            color: white !important;
            @extend .bg-gradient-success;
        }
    }
    &__primary {
        padding: $e-card-p;
        min-height: 85px; 
    }
    &__title {
        font-size: 1.25rem;
        font-weight: $font-weight-normal;
        margin-bottom: 0px; 
        line-height: $e-card-label-width / 2;
        letter-spacing: .0125em;
        overflow: hidden;
        white-space: nowrap;
    }
    &__sub {
        font-size: .875rem;
        line-height: $e-card-label-width / 2; 
        font-weight: $font-weight-normal;
        white-space: nowrap;
        opacity: .6;
        overflow: hidden;
        margin-bottom: 0;
    }
    &__label {
        float: left;
        width: $e-card-label-width;
        height: $e-card-label-width;
        border-radius: 50%;
        text-align: center;
        margin-right: -$e-card-p;
        &:last-of-type {
            margin-right: $e-card-p;
        }
        &_ex-type {
            border: $e-card-label-border-width solid currentColor;
            // margin-right: $e-card-p;
            padding: $e-card-icon-padding;
            font-size: $e-card-icon-size;
            line-height: 1;
            .e-card_course-1 & {
                color: white !important;
                background-color: $color-course-1;
            }
            .e-card_course-2 & {
                color: white !important;
                background-color: $color-course-2;
            }
        }
        &_theory {
            border: $e-card-label-border-width solid currentColor;
            // margin-right: $e-card-p;
            padding: $e-card-icon-padding;
            font-size: $e-card-icon-size;
            line-height: 1;
            background-color: $success;
        }
        &_ex-complete {
            border: $e-card-label-border-width solid currentColor;
            // margin-right: $e-card-p;
            padding: $e-card-icon-padding;
            font-size: $e-card-icon-size;
            line-height: 1;
            background-color: $success;
            border-style: dashed;
        }
        svg {
            display: inline-block;
            width: 1em;
            height: 1em;
            fill: currentColor;
            vertical-align: baseline;
        }
    }
    // &__icon {
    //     display: inline-block;
    //     width: ;
    //     height: ;
    //     margin: ;
    //     font-size: ;
    //     line-height: 1;
        // vertical-align: inherit !important;
        // svg {
        //     width: 100%;
        //     height:  100%;
        //     color: currentColor;
        // }
    //}
    &__secondary {
        padding: 0 $e-card-p $e-card-p/2 $e-card-p;
        // min-height: 52px;
        // p {
        //     margin: 0;
        //     font-size: .875rem;
        //     color: white;
        // }
    }
    &__topic {
        margin: 0;
        font-size: .875rem;
        line-height: 1.563;
        font-weight: $font-weight-normal;
        min-height: 45px;
        padding-bottom: $e-card-p/2;
    }
    &__state-wrapper {
        padding: 0 0 $e-card-p/2 0;
        text-align: center;
    }
    &__state {  // button: begin, proceed etc.
        display: inline-block;
        font-weight: $font-weight-normal;
        font-size: .875rem;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        border-width: 1px; 
        border-style: solid;
        border-color: currentColor;
        padding: 4px $e-card-p;
        border-radius: 4px;
        opacity: .8;
        text-transform: uppercase;
        letter-spacing: .08929em;
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
    &__actions {
        text-align: left;
        padding: $e-card-p/3 $e-card-p $e-card-p/2 $e-card-p;
    }
    &__ribbon-wrapper {
        position: absolute;
        top: -1px;
        right: -1px;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    &__ribbon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%) translate(-40px, 40px) rotate(45deg);
        padding: 3px 20px;
        display: inline-block;
        min-width: 200px;
        text-align: center;
        box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #0000001a, 0 1px 5px 0 #0000001a;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &_next {
            background-color: $success;
            color: white
        }
    }
}

