$nav-font-size: 14px;
$nav-disabled-opacity: 0.5;
$nav-btn-size: 40px;

$dot-container-height: 40px;
$dot-width: 0.9375rem;
$dot-height: 0.9375rem;
$dot-margin: 5px 7px !default;

@mixin owl-theme-variant( // Mixin for colors and borders

    $owl-color-1: $primary,
    $owl-color-2: white
    // $owl-color-3: NOT USED
    
) {
    $nav-color: $owl-color-2;
    $nav-color-hover: $owl-color-2;
    //$nav-background: NOT USED
    $nav-background: $owl-color-1;
    $nav-background-hover: $owl-color-1;
    
    $dot-background: $owl-color-1;
    $dot-background-hover: darken($dot-background, 7%);

    color: $owl-color-1;
    .owl-nav {
        [class*='owl-'] {
            color:  $nav-color;
            background: $nav-background;
            overflow: hidden;
            svg {
                path {
                    fill: currentColor;
                }
            }
            &:hover {
                color: $nav-color-hover;
                background: $nav-background-hover;
                
            }
            &.disabled:hover {
                background-color: $nav-background;
                color: $nav-color;
            }
            &:not(.disabled) {
                @include states;
            }
        }
    }
    .owl-dots {
        .owl-dot {
            span {
                background: $dot-background;
                border: 3px solid transparent;
            }
            &:hover {
                span {
                    background-color: $dot-background-hover;
                }
            }
            &.active {
                span {
                    background-color: transparent;
                    border-color: currentColor;
                }
                &:hover {
                    span {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.owl-theme { // Default theme with primary colors

    .owl-nav {
        margin-top: 10px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        position: relative;
       
        [class*='owl-'] {
            position: absolute;
            display: block;
            width: $nav-btn-size;
            height: $nav-btn-size;
            border-radius: 50%;
            box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #0000001a,0 1px 5px 0 #0000001a;
            font-size: $nav-font-size;
            margin: 0;
            padding: 0;
            cursor: pointer;
            overflow: hidden;
            svg {
                top: 50%;
                left: 50%;
                width: $nav-btn-size/2;
                height: $nav-btn-size/2;
                position: absolute;
                transform: translate(-50%, -50%);
            }
            &:hover {
                text-decoration: none;
            }
            // &:not(.disabled):hover {
            //     text-decoration: none;
            // }
        }
        .owl-prev {
            left: $grid-gutter-width / 2;
        }
        .owl-next {
            right: $grid-gutter-width / 2;
        }
        .disabled {
            opacity: $nav-disabled-opacity;
            cursor: default;
        }
    }
    .owl-nav.disabled+.owl-dots {
        margin-top: 0px;
    }
    .owl-dots {
        margin-top: 0 !important;
        height:  $dot-container-height;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-tap-highlight-color: transparent;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;
            outline: none !important;
            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                display: block;
                -webkit-backface-visibility: visible;
                transform: scale(0.6);
                transition: 0.3s ease-in-out;
                border-radius: 50%;
            }
            &.active {
                span {
                    transform: scale(0.9);
                }
                &:hover {
                    cursor: unset;
                }
            }
        }
    }
    @include owl-theme-variant;
}

.owl-theme-hero {
    .owl-dots {
        height: auto !important;
    }
    .owl-item-wr {
        margin: 0 auto;
        img {
            margin: 0 auto;
            // max-width: 780px;
        }
    }
}

.owl-theme-related { @include owl-theme-variant($owl-color-1: $warning, $owl-color-2: black); }
.owl-theme-featured { @include owl-theme-variant($owl-color-1: $primary, $owl-color-2: white); }

.owl-item-wrapper {
   
    @extend .col;
    @include media-breakpoint-down(sm) { 
        padding-left: 2px;
        padding-right: 2px;
    }
    .owl-item.active & {
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-only(xs) {
            max-width: 350px;  
            transition: all .25s linear;  
        }
    }
}
