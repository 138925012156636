.arrow {
    svg {
        fill: #bdc4c9;
    }
    &-hero-1 {
        position: absolute;
        left: 28%;
        svg {
            height: 180px;
        }
    }
    &-vertical-1 {
        svg {
            height: 120px;
        }
    }
    &-rounded-1 {
        svg {
            transform: translate(120%);
            height: 120px;
        }
    }
    &-rounded-2 {
        svg {
            transform: translate(-120%);
            height: 120px;
        }
    }
}