// Social login buttons

$sn-btn-height: 40px;

.sn-btn {
    &-wrapper {
        margin-bottom: 10px; 
        height: $sn-btn-height; 
        position: "relative";
    }
    border: none; 
    height: $sn-btn-height;
    display: flex; 
    align-items: center;
    border-radius: 4px;  
    padding: 0px 0px 0px 8px; 
    font-size: 16px;
    font-weight: normal; 
    outline: currentcolor none medium; 
    box-shadow: none; 
    cursor: pointer;
    margin: 0px; 
    text-align: left;  
    background-clip: padding-box; 
    width: 100%;
    &:hover {
        text-decoration: none;
    }
    svg {
        height: 24px;
        width: 24px;
        fill: currentColor;
        stroke-width: 0;
        vertical-align: middle;
    }
    &-facebook {
        background-color: #4267b2;
        svg { color: white; }
        .sn-btn-text { color: white; }
        position: absolute;
        top: 0;
        left: 0;
    }
    &-google {
        background-color: #4285F4;
        .sn-btn-text { color: white; }
    }
    &-vkontakte {
        background-color: #4285F4;
        overflow: hidden;
        .sn-btn-text { color: white; margin: 0 4px; }
    }
    &-text {
        display: flex;
        margin-right: 8px;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        padding-bottom: 1px;
    }
    &-img {
    	height: 100%;
    	img {
    		width: auto;
    		height: 100%;
    	}
    }
}

// Facebook forced overlay
#fb-btn {
    cursor: pointer;
    position: relative;
    &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}
