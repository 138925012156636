.arrow-box {
  position: relative;
  background: $success;
  box-shadow: 0 5px 10px -2px #0003, 0 2px 2px 0 #0000001a,
    0 1px 5px 0 #00000021;
  border-radius: 5px;
  padding: 10px;
  color: white;
  &-title {
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
  }
  &-sub {
    font-size: 0.75rem;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 0;
    padding: 10px;
    display: inline-block;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -25px;
      width: 25px;
      height: 100%;
      background-image: str-replace(
        url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 25 100' xml:space='preserve'%3E%3Cpolygon fill='#{$success}' points='0,0 25,50 0,100'/%3E%3C/svg%3E"),
        "#",
        "%23"
      );
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: left;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -25px;
      width: 25px;
      height: 100%;
      background-image: str-replace(
        url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 25 100' xml:space='preserve'%3E%3Cpolygon fill='#{$success}' points='0,0 25,0 25,100 0,100 25,50'/%3E%3C/svg%3E"),
        "#",
        "%23"
      );
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: right;
    }
  }
}
