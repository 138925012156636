//
// Width like w-sm-50 or w-xl-25
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $size, $length in $sizes {
      .w#{$infix}-#{$size} { width: $length !important; }
    }
  }
}