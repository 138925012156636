
@mixin img-aspect-ratio($width, $height) {
    padding-bottom: ($height / $width) * 100%;
}

.img {
    &-preloader {
        position: relative;
        overflow: hidden;
        margin: 0;
        img,
        svg {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &-bg { // 16x8 grey wave pattern
            background-image: url("data:image/gif;base64,R0lGODlhEAAIAIABAN3d3QAAACH5BAEAAAEALAAAAAAQAAgAAAIRjAMJx5f8Wmxo0WuUxbz7/xUAOw==");
        }
    }
    &-ratio-3-4 { // e.g. 450x600
        @include img-aspect-ratio(3, 4);
    }
    &-ratio-4-3 { // e.g. 800x600
        @include img-aspect-ratio(4, 3);
    }
    &-ratio-3-2 { // e.g. 750x500 like in exercises
        @include img-aspect-ratio(3, 2);
    }
}

