$font-family-base: 'Roboto', 'Arial', sans-serif;
$yiq-contrasted-threshold:    170;  
$enable-gradients: 1;
$headings-font-weight: $font-weight-bold;   // 500 is default

$spacer: 1rem; 
$spacers: map-merge(
  (
    0: 0,
    1:  ($spacer * .25),
    2:  ($spacer * .5),
    3:  $spacer,
    4:  ($spacer * 1.5),
    5:  ($spacer * 2),
    6:  ($spacer * 2.5),
    7:  ($spacer * 3),
    8:  ($spacer * 3.5),
    9:  ($spacer * 4),
    10: ($spacer * 4.5),
    11: ($spacer * 5)
  ),
  $spacers
);

$font-sizes: ();
$font-sizes: map-merge(
  (
    1: $font-size-base,
    2: $font-size-base * 1.25,
    3: $font-size-base * 1.50,
    4: $font-size-base * 1.90,
    5: $font-size-base * 2.75,
    6: $font-size-base * 3.50,
    7: $font-size-base * 4.50,
    8: $font-size-base * 5.50,
    9: $font-size-base * 6.00 
  ),
  $font-sizes
);

$display5-size: map-get($font-sizes, 5);
$display6-size: map-get($font-sizes, 4);   
$display7-size: map-get($font-sizes, 3);
$display5-weight: 300;
$display6-weight: 300;
$display7-weight: 300;

body {
  font-weight: $font-weight-light;
}

//
// Color. variables overriding
// Adding new colors
//
$primary:         $color-1;
$secondary:       #8189A9;    
$success:         $color-correct;
$info:            $color-info;
$danger:          $color-wrong;

$theme-colors: map-merge( 
  $theme-colors,
  (
    "primary":          $primary,
    "secondary":        $secondary,
    "success":          $success,
    "info":             $info,
    "danger":           $danger,

    "proceed":          $color-proceed, 
    "improove":         $color-efficiency, 
    "filter":           $color-filter, 
    "locked":           $color-locked,
    "course-0":         $color-course-0,
    "course-1":         $color-course-1,
    "course-2":         $color-course-2,
    "course-locked":    $color-locked,
    "correct":          $color-correct,
    "wrong":            $color-wrong,
    "white-secondary":  $color-white-secondary
  
  )
);

//
// Misc.
//
[data-toggle='collapse'] {
  cursor: pointer;
}