//
// 'f' stands for 'framed'
//

@mixin f-card-variant($color, $value) {
    &-#{$color} {
        background-color: $value;
        &:hover {
            color: $value;
        }
    }
    &-#{$color} &-content {
        color: $value;
    }
    &-#{$color} &-btn {
        @include button-hero-variant($value);
    }
    &-#{$color}:hover &-btn {
        @extend .f-card-btn:hover;
    }
    &-#{$color} &-img {
        svg {
            fill: $value;
        }
    }
}

.f-card {
    display: block;
    &-mw250 {
        max-width: 250px + 15px;
    }
    &-mw300 {
        max-width: 300px;
    }
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 35px #8c98a420;
    transition: 0.17s ease-out;
    padding: 10px;
    height: 100%;
    &:hover {
        text-decoration: none;
        transition-duration: 0.12s;
        transform: translate3d(0, -.25rem, 0);
        box-shadow: 0 .75rem 1.5rem 0 #00000033;
    }
    &-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 2px;
        padding: 15px;
        height: inherit;
    }
    // &-badge {
    //     @extend .label;
    //     z-index: 2;
    //     position: absolute;
    //     top: 0;
    //     right: 50%;
    //     transform: translate(50%, -50%);
    //     font-size: 120%;
    // }
    &-btn {
        @extend .btn;
        @extend .btn-block;
        @extend .mt-3;
        &:hover {
            transform: none !important;
            box-shadow: none !important;
        }
        &-wrapper {
           margin-top: auto;
        }
    }
    // &-price {
    //     text-align: center;
    //     white-space: nowrap;
    //     & > div {
    //         display: inline-block;
    //         margin: 0 5px;
    //     }
    //     &-left {
    //         @extend .display-3;
    //         @extend .fw-b;
    //         letter-spacing: 1px;
    //     }
    //     &-right {
    //         text-align: left;
    //         line-height: 1.2;
    //     }
    //     &-fract {
    //         @extend .fw-b;
    //     }
    // }
    &-img {
        width: 50%;
        margin: 1rem auto 1.5rem auto;
    }
    // &-info { 
    //     color: $secondary;
    //     margin-bottom: 0;
    //     font-size: 90%;
    //     line-height: 1.4;
    // }
    @each $color, $value in (
        'primary': $primary,
        'success': $success
    ) {
        @include f-card-variant($color, $value);
    }
} 

