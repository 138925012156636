.ie-notification  {
    // cursor: pointer;
    position: absolute;
    position: fixed;
    z-index: 111111;
    width: 100%;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid darken($light, 7.5%);
    text-align: center;
    color: black;
    background-color: $light;
    padding: 15px;
    animation-name: ie-notif-spec;
    animation-duration: .8s;
    &-pad {
        display: inline-block;
        max-width: 600px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    &-icon {
        padding-bottom: 10px;
    }
    &-title {
        @extend .font-weight-bold;
        line-height: 2;
    }
    &-desc {
        color: lighten(black, 35%);
    }
    &-buttons {
        display: block;
        text-align: center;
        a {
            @extend .btn;
            @extend .btn-primary;
            background-color: $primary;
            &:hover { 
                background-color: darken($primary, 7.5%)
            }
            &:active {
                background-color: darken($primary, 10%)
            }
        }
    }
}

@keyframes ie-notif-spec {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
  }


