.features {
    &-item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 28px;
        @include media-breakpoint-down(xs) {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &-header {
        @extend .text-secondary;
        @extend .fs-1;
        @extend .fw-n;
        margin: 0 0 0 15px;
    }
    &-text {
        @extend .text-secondary;
    }
} 

.features-vert {
    &-item {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        text-align: center;
        align-items: center;
        // margin-bottom: 1.5rem;
    }
    &-header {
        @extend .fs-2;
        @extend .fw-n;
        margin-bottom: 1rem;
    }
    &-text {
        @extend .text-secondary;
        @include media-breakpoint-up(md) { 
            margin-bottom: auto;
        }
    }
    &-icon {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    &-btn {
        margin-top: 1.3rem;
    }
} 


