.section-test {
    background-image: linear-gradient(150deg, $success 0%, $primary 100%);
    position: relative;
    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('/templates/lin/assets/images/landing-images/test-pattern.svg');
        background-position: center;
        opacity: .1;
    }
}
