@each $course, $color in $course-bg-colors {
    .bg-#{$course} {
        background-color: $color !important;
    }
}

@each $course, $color in $course-colors {
    .color-#{$course} {
        color: $color !important;
    }
    a.text-#{$course} {
        color: $color !important;
        &:hover,
        &:focus {
            color: $color !important;
        }
    }
}



