.headerbox {
    @extend .container;
    padding-top: 32px;
    padding-bottom: 32px;
    &__wrapper {
        @extend .container-fluid;
        color: white;
        background-color: $primary;
        padding-right: 0;
        padding-left: 0;
        &_course-1 {
            color: white;
            background-color: $color-course-1;
        }
        &_course-2 {
            color: white;
            background-color: $color-course-2;
        }
    }
    &__breadcrumbs {
        margin-bottom: 32px;
    }
    &__icon {
        @extend .icon-inline;
        @extend .fs-3;  
        @extend .fs-md-5; 
        @extend .fs-xl-6;
    }
    &__title {
        font-size: 24px;
        @include media-breakpoint-up(md) { font-size: 32px; }
        @include media-breakpoint-up(xl) { font-size: 50px; }
        margin: 0 0 16px;
        font-weight: $font-weight-normal;
        line-height: 1.2;
    }
    p {
        font-size: 1.25rem;
        font-weight: $font-weight-light;
        color: $color-white-secondary;
    }

}