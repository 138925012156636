html {
  position: relative;
  min-height: 100%;
}

body {
  min-width: $body-minwidth;
  &.footer-margin {
    @each $breakpoint in map-keys($grid-breakpoints) {
      // For statbar calc.
      @include media-breakpoint-up($breakpoint) {
        margin-bottom: map-get($footer-height, $breakpoint) !important;
      }
    }
  }
  &.footer-margin-course {
    @each $breakpoint in map-keys($grid-breakpoints) {
      // For statbar calc.
      @include media-breakpoint-up($breakpoint) {
        margin-bottom: map-get($footer-height, $breakpoint) + $statbar-height !important;
      }
    }
  }
  &.modal-open {
    header {
      padding-right: inherit;
      .header {
        padding-right: inherit;
      }
    }
    footer {
      padding-right: inherit;
    }
  }
}

header {
  z-index: 2;
}

main {
  position: relative;
  z-index: 1;
}

ol,
ul {
  padding-left: 20px;
}

// Это временно. В конце надо компиллировать бутстрап с автопрефиксером
.custom-select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
