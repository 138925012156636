$statbar-height: 30px;
$body-minwidth: 320px;
$footer-height: (
    "xs":       610px,
    "sm":       400px, //375px,
    "md":       350px, //305px,
    "lg":       305px, //265px,
    "xl":       305px //245px
);

$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-bold:          700;
$font-family-sans-serif:    "Roboto", sans-serif;
$headings-font-weight:      $font-weight-normal;

$primary:                   #039CFD;
$color-1:                   #039CFD;
$color-white-secondary:     rgba(255, 255, 255, 0.7);

$color-info:                #00c9a7;
$color-wrong:               #ff1d45;
$color-correct:             #22D431;
$color-locked:              #ffd543;
$color-disabled:            #2C4156;
$color-efficiency:          #ffd543;
$color-progress:            #00bb00;
$color-proceed:             #00bb00;
$color-filter:              #00bb00;

//
// Courses customisation
//
$color-course-0:            $color-1;
$color-course-1:            $color-1;
$color-course-2:            #80004c;
$color-course-4:            #2a012a;

$course-colors: 
( 
    "course-0":            #ffffff, 
    "course-1":            #ffffff,
    "course-2":            #ffffff,
    "course-4":            #ffffff   
); 

$course-bg-colors: 
( 
    "course-0":            $color-course-0, 
    "course-1":            $color-course-1, 
    "course-2":            $color-course-2, 
    "course-4":            $color-course-4,
    "course-disabled":     transparentize($color-disabled, .6), 
    "course-locked":       $color-locked
);  


$navbar-dark-color:   white;