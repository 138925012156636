.player-simple {
    cursor: pointer;
    transition: color .2s, background-color .2s, padding .2s, border-radius .2s;
    @include media-breakpoint-up(lg) {
        &:hover {
            border-radius: 4px;
            padding: 0 3px;
            border: 1px dotted $primary;
        }
    }   
    &--playing {
        color: white;
        background-color: $primary;
        border-radius: 4px;
        padding: 0 3px;
        @include media-breakpoint-up(lg) {
            border: 1px solid $primary;
        }
    }
}
.player-simple-icon {
    margin-right: .15em;
}