.ya-share2 {
    a {
        &:hover {
            cursor: pointer;
        }
    }
    &_custom-size &__icon {
        height: 30px !important;
        width: 30px !important;
        background-size: 30px 30px !important;
    }
}

