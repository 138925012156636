@import (reference) "custom-standards.less";
// Local variables
@color-googleplus: #dd4b39;
@color-facebook: #3b5998;
@color-twitter: #1DA1F2;
@color-linkedin: #0077B5;
// /Local variables
.sb {
    display: flex;
    //justify-content: space-around;
    justify-content: center;
    &__btn {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 10px;
        margin: 0 5px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.31);
        transition: box-shadow 0.15s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
        
        &:focus,
        &:active {
            box-shadow: none;
        }
        &_fb {
            background-color: @color-facebook;
        }
        &_gp {
            background-color: @color-googleplus;
        }
        &_tw {
            background-color: @color-twitter;
        }
        &_in {
            background-color: @color-linkedin;
        }
        svg {
            width: 30px;
            fill: white !important;
        }
    }
}