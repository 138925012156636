@import (reference) "custom-standards.less";
// Local variables
// /Local variables
.d-card {
    padding: .5rem 1.25rem;
    &-row {
        align-items: center;
    }
    &-desc {
        // not used yet
    }
    &-val {
        flex-grow: 0 !important;
        white-space: nowrap;
    }
}