@mixin button-hero-variant($color, $color-hover: color-yiq($color)) {
    color: $color;
    background: rgba($color, 0.1);
    border-color: transparent;
    font-weight: $font-weight-bold;
    transition: transform 0.2s ease-in-out;
    padding: 0.75rem 1rem !important;
    &:hover {
        color: $color-hover;
        background: $color;
        box-shadow: 0 4px 17px rgba($color, 0.35);
        transform: translateY(-3px);
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }
    &.disabled,
    &:disabled {
        // not yet described
    }
    &:active {
        box-shadow: none !important;
    }
}

@mixin button-hero2-variant($color, $text-color: color-yiq($color)) {
    color: $text-color;
    background: $color;
    border-color: transparent;
    font-weight: $font-weight-bold;
    transition: transform 0.2s ease-in-out;
    padding: 0.75rem 1rem !important;
    &:hover {
        color: $text-color;
        box-shadow: 0 4px 17px rgba($color, 0.35);
        transform: translateY(-3px);
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }
    &.disabled,
    &:disabled {
        // not yet described
    }
    &:active {
        box-shadow: none !important;
    }
}

// Example: .btn-primary-darker
@each $color, $value in $theme-colors {
    .btn-#{$color}-darker {
        @include button-variant(darken(desaturate($value, 15%), 4%), darken(desaturate($value, 15%), 6.5%));
    }
}

// Example: .btn-hero-primary
@each $color, $value in $theme-colors {
    .btn-hero-#{$color} {
        @include button-hero-variant($value);
    }
}

// Example: .btn-hero2-primary
@each $color, $value in $theme-colors {
    .btn-hero2-#{$color} {
        @include button-hero2-variant($value);
    }
}

// Example: .btn-t2-primary
@each $color, $value in $theme-colors {
    .btn-t2-#{$color} {
        @include button-variant($background: $value, $border: color-yiq($value), $hover-border: color-yiq($value));
        // @include button-outline-variant($color: color-yiq($value), $color-hover: color-yiq($value), $active-background: darken($value, 7.5%), $active-border: color-yiq($value));
    }
}