@import "custom-standards.less";
// .mobile(@rules) {
//     @media (max-width: 576px) {
//         @rules();
//     }
// }
h1 {
    //  color: @color-1;
}

.exercise {
    position: relative;
    z-index: 1;
    margin-top: 1rem;
    .container {
        margin-bottom: 20px;
    }
    & a:hover {
        text-decoration: none;
    }
    .card-body {
        max-width: 700px;
        margin: 0 auto;
        width: 100%;
        .sm( {
            text-align: center;
        }
        );
    }
}

.exercise-scrim {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;

    z-index: 1;

    width: 100%;
    height: 100%;

    opacity: 0;
    background-color: #ffffffc4;
    border-radius: inherit;
    
    &_visible {
        display: flex;
        transition: .3s opacity;
        opacity: 1;
    }
}

.exercise-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

.exercise-progress div:first-child {
    .md( {
        font-size: 2rem;
    }
    );
    .lg( {
        font-size: 2.5rem;
    }
    );
    .xl( {
        font-size: 2.5rem;
    }
    );
    color: gray;
}

.exercise-progress div:first-child .accepted {
    color: @color-1;
}

.exercise-progress div:first-child .right {
    color: @color-green;
    &:hover {
        color: darken(@color-green, 10%);
    }
}

.exercise-progress div:first-child .wrong {
    color: @color-red;
    &:hover {
        color: darken(@color-red, 10%);
    }
}

.exercise-progress div:first-child .skipped {
    color: @color-orange;
    &:hover {
        color: darken(@color-orange, 10%);
    }
}

.exercise-progress div:last-child {
    white-space: nowrap;
    margin-left: auto;
}

.p-wrapper {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 300;
    display: inline-block;
    &>* {
        text-align: left;
    }
    width: 100%; // .sm( {
}



.exercise-img {
    display: flex;
    justify-content: center;
    margin-bottom: 1.6rem;
    width: 100%;
    .md({
        width: 450px;
    });
    margin-left: auto;
    margin-right: auto;
    &__content {
        position: relative;
        display: inline-block;
        img {
            max-width: 100%;
        }
    }
    &__copyright {
        position: absolute;
        bottom: 0;
        right: .2rem;
        font-size: .8rem;
        a {
            white-space: nowrap;
            line-height: 0.8rem;
            color: rgba(150, 150, 150, 0.7);
            &:hover {
                color: darken(rgba(150, 150, 150, 0.7), 20%);
            }
        }
        .sm( {
            bottom: 0.2rem;
            right: 1rem;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            a {
                position: absolute;
            }
        }
        );
    }
}

.ans-btn-group {
    margin-left: -3px;
    margin-right: -3px;
    .btn {
        margin: 3px;
        &:not(:disabled) {
            box-shadow: @shadow-1;
        }
        &:active {
            box-shadow: none;
        }
        &:disabled {
            background-color: gray;
            color: white;
        }
        &.right {
            background-color: @color-green;
        }
        &.wrong {
            background-color: @color-red;
        }
        &.right:disabled,
        &.btn.wrong:disabled {
            opacity: 1;
        }
    }
}

.exercise {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0px;
        border-bottom: 1px solid @color-1;
        font-weight: 300;
        width: 2.5em;
    }
    input {
        border: 0px;
        border-bottom: 1px solid @color-1;
        padding: 0 1px;
        font-weight: 300;
        width: 1.5em;
        min-width: 1.5em;
        max-width: 600px;
        border-radius: 0;
        outline: none;
        &:focus {
            background-color: lighten(@color-1, 39%);
        }
    }
    #input_probe {
        font-weight: 300;
        font-size: 120%;
        white-space: nowrap;
        padding: 0 1px;
    }
    .select2-container {
        margin: 5px 0;
        width: auto !important;
        min-width: 30px;
        left: initial !important;
        right: initial !important;
        .select2-selection--single {
            height: 34px;
        }
        .select2-selection__rendered {
            padding: 0 15px 0 0;
            color: @color-1;
            line-height: 27px;
            font-weight: 300;
            font-size: 120%;
        }
        .select2-selection {
            border-width: 0 0 1px 0;
            border-color: @color-1;
            border-radius: 0;
            outline: none;
        }
        .select2-selection__arrow {
            width: 10px;
            b {
                border-width: 4px 4px 0 4px;
                border-color: @color-1 transparent transparent transparent;
            }
        }
        &.select2-container--open {
            .select2-selection__arrow b {
                border-width: 0 4px 4px 4px !important;
                border-color: transparent transparent @color-1 transparent !important;
            }
        }
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: @color-1;
}


.av,
.exercise select,
.exercise input {
    font-size: 120%;
    color: @color-1;
}

.rt {
    color: @color-red;
}

.rtl {
    color: @color-red;
    text-decoration: line-through;
}

.rb {
    .bg-red-txt();
}

.rdot {
    border: 1px dotted @color-red;
    padding: 0 2px 0 2px;
    margin: 0 2px 0 2px;
}

.gt {
    color: @color-green;
}

.gb {
    .bg-green-txt();
}

.gbl {
    .gb();
    text-decoration: line-through;
}

.hltd {
    background-color: @color-1;
    color: white;
}

.exercise .lead.hvrbl span:hover {
    .hltd();
    cursor: pointer;
}

.exercise.t2 {
    .fixheight {
        position: relative;
        padding-bottom: 2.5em;
        box-sizing: content-box;
    }
}

.exercise.t5 .lead,
.exercise.t6 .lead {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.exercise.t5 .lead span,
.exercise.t6 .lead span,
    {
    &.rb,
    &.gb,
    &.gbl {
        padding: 1px 3px 1px 3px;
    }
    &.rdot {
        padding: 0 2px 0 2px;
        margin: 0 2px 0 2px;
    }
} // === DRAG-n-DROP ===
.dd-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; //width: 600px;
    //justify-content: center;
}

.dd-line_base {
    margin-right: -3px;
    margin-left: -3px;
    .dd-items {
        min-height: 42px;
        width: 100%;
    }
} //.dd-line_base:not(:last-child) {
//    margin-bottom: 2rem;
//}
.dd-line_base {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

/*
// +-+-+- Variant 1
.dd-line_target .dd-items .ui-sortable-placeholder,
.dd-line_target .dd-item:active,
.dd-line_target  .dd-item:hover {
    margin-left: 0;
    margin-right: 0;
}
*/

// +-+-+- Variant 2
.dd-line_target .dd-items:first-child .ui-sortable-placeholder:first-child,
.dd-line_target .dd-items:first-child .dd-item:first-child:active,
.dd-line_target .dd-items:first-child .dd-item:first-child:hover,
.dd-line_target .dd-plaintext+.dd-items .ui-sortable-placeholder,
.dd-line_target .dd-plaintext+.dd-items .dd-item:active,
.dd-line_target .dd-plaintext+.dd-items .dd-item:hover,
    {
    margin-left: 0;
}

.dd-line_target {
    //margin-bottom: .5rem;
    font-size: 1.25rem;
    font-weight: 300;
    >.dd-items.last {
        flex-grow: 1;
    }
    &.br {
        margin-top: -1rem;
    }
}

.dd-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    min-width: 2em;
    .dd-item {
        margin-right: 5px !important;
        &:last-child {
            margin-right: 0 !important;
        }
    }
    &.flupper .dd-item:first-child:first-letter {
        text-transform: uppercase;
    }
}

.dd-item,
.ui-sortable-placeholder {
    padding: .375rem .75rem;
    margin: 3px;
    border-radius: .25rem;
    min-height: 2.25rem;
}

.dd-item {
    cursor: pointer;
    background-color: @color-1;
    color: white;
    box-shadow: @shadow-1;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &:hover,
    &:active {
        background-color: darken(@color-1, 6%);
    } // &:active {
    //     transform: rotate(-5deg);
    // }
}

.dd-line_target {
    .dd-items {
        border-bottom: 1px dotted black;
    }
    .dd-item,
    .ui-sortable-placeholder,
    .dd-plaintext {
        margin-bottom: -.375rem;
    }
    .dd-item {
        background-color: transparent;
        color: inherit;
        box-shadow: none;
        transition: none;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
        position: relative;
        font-size: 1.25rem;
        font-weight: 300;
        &.hover,
        &.ui-sortable-helper {
            color: #fff;
            z-index: 1;
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: -10px;
                right: -10px;
                bottom: 0;
                cursor: pointer;
                background-color: #039CFD;
                color: white;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                white-space: nowrap;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                border-radius: .25rem;
                min-height: 2.25rem;
            }
        }
    }
}

.ui-sortable-placeholder {
    background-color: rgb(219, 219, 219);
    box-shadow: none;
    width: 70px; // temporary
}

.dd-plaintext {
    padding: .375rem 0;
    margin: 3px 0px;
}

@media (min-width: 576px) {
    .exercise.result .widget-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .exercise.result .widget-simple {
        width: 390px;
    }
}

@media (min-width: 768px) {
    .exercise.result .container {
        max-width: 640px;
    } //.exercise.result .widget-wrapper {display: flex; align-items: center;  flex-direction: column;}
    //.exercise.result .widget-simple {width: 390px;}
}

/*
@media (min-width: 992px) {
    .exercise.result .container {max-width: 640px;}
    .exercise.result .widget-wrapper {display: flex; align-items: center;  flex-direction: column;}
    .exercise.result .widget-simple {width: 390px;}
}

@media (min-width: 1200px) {
    .exercise.result .container {max-width: 640px;}
    .exercise.result .widget-simple {width: 390px;}
}
*/

.exercise.result h2 {
    color: #80898e !important;
    margin-bottom: 0 !important;
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.5;
}

.exercise.result .value {
    color: @color-1  !important;
    font-weight: bold;
    margin-top: 0 !important;
    font-size: 2.3rem;
    line-height: 1.1;
    margin-bottom: .2rem !important;
}

.exercise.result .value.right {
    color: @color-green  !important;
}

.exercise.result .value.wrong {
    color: @color-red  !important;
}

body.transparent {
    background: transparent !important;
}

.linkrow {
    display: inline-block;
    margin-bottom: 0.3rem;
}

.linkrow a {
    display: flex;
    align-items: center;
    & i {
        margin-right: 5px;
        font-weight: bold;
        font-size: 1.8rem;
    }
    & div:first-child {
        min-width: 3rem;
    }
}

.loading img {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 157, 253, 0.5);
}

.select2-dropdown {
    min-width: 150px;
    border: 1px solid #e1e1e1 !important;
}

.no-scroll {
    overflow: hidden;
    // height: 100%;
}

.select2-results__options {
    li[aria-disabled] {
        display: none;
    }
}

.fade-out {
    opacity: 0 !important;
    transition: 1s opacity;
} 

//
// Temporarily fixing the problem with 1366x768 monitors [ "-25px" "+20px" ]
//

@media (min-width: 1341px) and (max-width: 1386px) {
    .exercise {
        margin-top: .2rem;
        & > .container {
            margin-bottom: 0 !important;
        }
        .card-body {
            padding-top: .5rem !important;
            .card-text p {
                margin-bottom: .5rem !important;
            }
        }
        hr {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
    }
}



