.pricing {
    display: block;
    max-width: 250px + 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: lighten($primary, 40%);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 35px #8c98a420;
    transition: 0.17s ease-out;
    padding: 10px;
    color: $primary;
    height: 100%;
    &:hover {
        color: $primary;
        text-decoration: none;
        transition-duration: 0.12s;
        transform: translate3d(0, -.25rem, 0);
        box-shadow: 0 .75rem 1.5rem 0 #00000033;
    }
    &-primary {
        background-color: $primary;
    }
    &-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 2px;
        padding: 15px;
        height: inherit;
    }
    &-badge {
        @extend .label;
        z-index: 2;
        position: absolute;
        top: 0;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: 120%;
    }
    &-btn {
        @extend .btn;
        @extend .btn-block;
        @extend .btn-hero-primary;
        @extend .mt-3;
        &:hover {
            transform: none;
            box-shadow: none;
        }
        .pricing:hover & {
            @extend .pricing-btn:hover;
        }
        &-wrapper {
           margin-top: auto;
        }
    }
    &-price {
        text-align: center;
        white-space: nowrap;
        & > div {
            display: inline-block;
            margin: 0 5px;
        }
        &-left {
            @extend .display-3;
            @extend .fw-b;
            letter-spacing: 1px;
        }
        &-right {
            text-align: left;
            line-height: 1.2;
        }
        &-fract {
            @extend .fw-b;
        }
    }
    &-price-old {
        text-align: center;
    }
    &-price-linethrough {
        color: #fc3835;
        font-size: 150%;
        position: relative;
        &:after {
            border-bottom: 0.075em solid #fc3835;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: 50%;
            transform: rotate(-7deg) scale(1.2);
            width: 100%;
        }
    }
    &-img {
        width: 50%;
        margin: 1rem auto 1.5rem auto;
        svg {
            fill: $primary;
        }
    }
    &-info {
        color: $secondary;
        margin-bottom: 0;
        font-size: 90%;
        line-height: 1.4;
    }
} 
