//
// Widget v2
//

@import (reference) "custom-standards.less";
// Local variables
@widget-height: 128px;
@widget-padding: 15px;
// /Local variables

.widget {
    position: relative;
    width: 100%;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto; //border: 1px solid #0000001a;
    overflow: hidden;
    //border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    &_progress {
        background-color: @color-progress;
        color: white;
    }
    &_efficiency {
        background-color: @color-efficiency;
        color: white;
    }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        max-height: @widget-height;
    }
    &__label {
        border: 1px solid #0000002c;
        width: @widget-height - 2 * @widget-padding + 1;
        height: @widget-height - 2 * @widget-padding + 1;
        border-radius: 50%;
        background-color: inherit;
        display: inline-block;
        text-align: center;
        transition: all 0.2s ease-in-out;
        color: inherit;
    }
    &__stat {
        margin: 0;
        text-align: right;
        //max-width: 55%;
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: inherit;
    }
    &__data {
        font-size: 1.5rem;
        line-height: 1.5rem;
        display: block;
        font-weight: bold;
    }
    &__button {
        display: block;
        color: inherit;
        text-align: center;
        background-color: rgba(0, 0, 0, 27%);
        padding-top: 5px;
        padding-bottom: 5px;
        transition: all 0.2s ease-in-out;
        font-weight: bold;
        font-size: 1.2rem;
        &:hover,
        &:active {
            color: inherit;
            text-decoration: none;
        }
        .xl( {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
        }
        );
        .widget:hover & {
            .xl( {
                transform: translateY(0%);
            }
            );
        }
    }
    &__curtain {
        width: 100%;
        height: 128px;
        background-color: rgba(0, 0, 0, 5%);
        display: none;
        transition: all 0.2s;
        .xl( {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
        }
        );
        .widget:hover & {
            .xl( {
                transform: translateY(0%);
            }
            );
        }
    }
}