// Small devices (landscape phones, 576px and up)
.sm(@rules) {@media (min-width: 576px) {@rules();}} 

// Medium devices (tablets, 768px and up)
.md(@rules) {@media (min-width: 768px) {@rules();}}

// Large devices (desktops, 992px and up)
.lg(@rules) {@media (min-width: 992px) {@rules();}}
 
// Extra large devices (large desktops, 1200px and up)
.xl(@rules) {@media (min-width: 1200px) {@rules();}}

// Extra large devices (large desktops, 1617px and up)
.xxl(@rules) {@media (min-width: 1617px) {@rules();}}

// Common colors
@color-1:           #039CFD;
//@color-1:           #2470fd;
@color-filter:      #18ca00;
@color-locked:      #ffd543;
@color-efficiency:  #ffd543;
@color-progress:    #00bb00;
@color-green:       #35A541;
@color-red:         #EF5350;
@color-orange:      #F1B53D;

@color-course-0:    @color-1;
@color-course-1:    @color-1;
@color-course-2:    #800080;
@color-course-3:    #2a012a;

// Other options
@shadow-1: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

.bg-green-txt {
    background-color: @color-green;
    color: white;
}

.bg-red-txt {
    background-color: @color-red;
    color: white;
}