.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    background-color: #0000001a;
    min-width: $body-minwidth;
    @each $breakpoint in map-keys($grid-breakpoints) {
        // Footer height for statbar calculation
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            height: map-get($footer-height, $breakpoint)
        }
    }
    &-title {
        color: inherit;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 8px;
    }
    &-list {
        margin: 0;
        padding: 0;
    }
    &-list-item {
        list-style: none;
        margin-bottom: 8px;
        line-height: 1.25;
        a {
            color: inherit;
            font-size: 14px;
            line-height: inherit;
            &:hover {
                text-decoration: none;
                color: $color-1;
            }
        }
    }
}