@import (reference) "custom-standards.less";
// Local variables
// /Local variables
.test {
    max-width: 768px;
    margin-top: 1.5rem;
    // .sm({
    //     margin-top: 1rem;
    // });
    .md({
        margin: 3rem auto;
        padding-left: 3rem;
        padding-right: 3rem;
    });
    &__title {
        // color: @color-1;
        font-size: 1.8rem;
        line-height: 1.6rem;
        margin-bottom: 2rem;
        .md({
            font-size: 2rem;
            line-height: 1.8rem;
        });
        .lg({
            font-size: 2.5rem;
            line-height: 2.3rem;
        });
    }
    &__description {
        color: #37474F;
        margin-bottom: 2rem;
        .lg({
            font-size: 1.2rem;
            line-height: 1.6rem;
        });
    }

}