@import (reference) "custom-standards.less";

.rcard-col {
    padding: 15px;
}

.rcard {
    max-width: 500px;
    &__content-wrapper {
        background-color: transparent;
        border: none;
    }
    &__side {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: white;
        background-color: gray;

        //border-radius: .25rem;
        &_front {
            padding: 10px;
            display: inline-block;
            text-align: center;
        }
        &_back {
            width: 100%;
            text-align: center;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            //font-size: 1.5rem;
            //line-height: 1.3rem;

            font-size: 1.2rem;
            // 
            font-weight: 700;
            .lg({
               font-size: 1.5rem;
            });

            a {
                color: white;
                &:hover {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
    &__icon {
        line-height: 24px;
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: middle;

    }

    &__circliful-chart {
        display: inline-block;
        vertical-align: middle;
    }
}

