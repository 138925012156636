//
// Dialog card
//
.d-card {
    position: relative;
    // overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    padding: $grid-gutter-width*1.5 $grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width/2;
    @include media-breakpoint-up(sm) {
        padding: $grid-gutter-width;
    }
    @extend .shadow;
    &__wrapper {
        max-width: 910px;
        @extend .mx-auto;
    }
    &__close {
        position: absolute; 
        top: 0; 
        right: 0;
        a {
            display: block;
            width: $grid-gutter-width*1.5;
            height: $grid-gutter-width*1.5;
            padding: $grid-gutter-width/2;
            font-size: $grid-gutter-width/2;
            line-height: 1;
            text-decoration: none;
            opacity: .6;
            color: $secondary;
            &:hover {
                text-decoration: none;
                opacity: 1;
            }
            svg {
                display: inline-block;
                width: 1em;
                height: 1em;
                fill: currentColor;
                vertical-align: baseline;
            }
        }
    }
    &__content {
        text-align: center;
        &_right {
            @include media-breakpoint-up(md) {
                width: 50%;
                margin-left: auto;
                margin-right: $grid-gutter-width /2;
            }
        }
    }
    &__title {
        position: relative;
        max-width: 450px;
        margin-bottom: $grid-gutter-width;
        @extend .mx-auto;
        @extend .display-6;
    }
    &__subtitle {
        position: relative;
        max-width: 450px;
        @extend .mx-auto;
        @extend .display-7;
    }
    &__desc {
        position: relative;
        max-width: 360px;
        margin-top: -.5 * $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        color: $secondary;
        @extend .mx-auto;
        
    }
    &__actions {
        position: relative;
        max-width: 260px;
        @extend .mx-auto;
        form {
            .form-group {
                margin-bottom: 10px;
            }
        }
    }
    &__small {
        margin: $grid-gutter-width / 3 auto $grid-gutter-width / 2;
        color: $secondary;
        @extend .small;
        a { @extend .a-underline; }
    }
    &__footer {
        position: relative;
        max-width: 350px;
        margin-top: $grid-gutter-width * 1.5;
        @extend .mx-auto;
        p {
            @extend .d-card__small;
        }
    }

    &__bg-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        
        
    }
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 800px;
        background-size: cover;
        background-position: top right;
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }

    }
    &__bg_img04 {
        // background-image: url("/templates/lin/assets/images/landing-images/hero-images/lingbase-hero-01.png");
        background-image: url("/templates/lin/assets/images/landing-images/lingbase-004.png");
        
    }        
    &__bg_img08  {
        transform: translateX(-70px);
        background-image: url("/templates/lin/assets/images/landing-images/hero-images/lingbase-hero-01.png");
    }
    &__bg_img09  {
        transform: translateX(-100px);
        background-image: url("/templates/lin/assets/images/landing-images/hero-images/lingbase-hero-01.png");
    }

}