.card {
    &-course {
        border: 0;
        &-0 {
            background-color: $color-course-0;
            color: color-yiq($color-course-0);
        }
        &-1 {
            background-color: $color-course-1;
            color: color-yiq($color-course-1);
        }
        &-2 {
            background-color: $color-course-2;
            color: color-yiq($color-course-2);
        }
    }
}

// .card {
//     &-course {
//         h2 {
//             color: #303030;
//         }
//         border: 0;
//         box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
//         &-0 {
//             background-color: white;
//             color: #767676;
//         }
//         &-1 {
//             background-color: white;
//             color:  #767676;
//         }
//         &-2 {
//             background-color: white;
//             color:  #767676;
//         }
//     }
// }




