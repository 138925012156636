// Local variables
// $swidget-dark: #313a46;
// $swidget-light: #98a6ad;
$swidget-dark: white;
$swidget-light: rgba(255, 255, 255, 0.6);

.swidget {
    float: right;
    margin-right: 15px;
    font-size: 14px;
    color: $swidget-light;
    line-height: $statbar-height;
    @include media-breakpoint-down(sm) {
        cursor: pointer; // just in case
    }
    &.showdesc {
        .swidget-i path {
            fill: $swidget-dark;
        }
        .swidget-toggle {
            display: inline;
        }
    }
    &-chart {
        display: inline-block;
        width: 14px;
        height: 14px;
        padding: 1px;
        border-radius: 50%;
        background-color: white;
        svg {
            display: block;
        }
    }
    &-value {
        font-size: 18px;
        font-weight: $font-weight-bold;
        color: $swidget-dark;
    }
    &-i {
        display: inline-block;
        vertical-align: 5px;
        width: 10px;
        height: 10px;
        path {
            fill: currentColor;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    &-toggle {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}