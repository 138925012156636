.notice {
    background-color: white;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #0000001a, 0 9px 46px 8px #0000001a;
    @include media-breakpoint-up(sm) { border-radius: 4px; }
    &__scrim {
        height: 100vh;
        background-color: #0000004d;
    }
    &__wrapper {
        margin-right: auto;
        margin-left: auto;
        @include media-breakpoint-up(sm) { 
            width: map-get($container-max-widths, sm); 
            padding-top: 40px;
        }
    }
    &__primary {
        padding: $grid-gutter-width / 2;
        border-bottom: 1px solid #E3E3E3;
    }
    &__title {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: $font-weight-normal;
        letter-spacing: .0125em;
    }
    &__description {
        // font-size: 1.25rem;
        // line-height: 1.5rem;
        font-weight: $font-weight-normal;
        letter-spacing: .03125em;
        margin: 0;
        opacity: .6;
    }
    &__actions {
        text-align: right;
        padding: $grid-gutter-width/2 $grid-gutter-width/2;
        &> :not(:first-child) {
            margin-left: $grid-gutter-width/4;
        }
        &> :not(:last-child) {
            margin-right: $grid-gutter-width/4;
        }
    }
}