.navbar {
    $this: &;

    & &-buttons {
        display: flex; 
        align-items: center;

        @include media-breakpoint-up(lg) {
            order: 1;

        }

        & button {
            $btn-width: 3.4rem;
            $btn-padding: .75rem;

            width: $btn-width;
            height: $btn-width;
            padding: $btn-padding;
            margin-left: $navbar-padding-x;

            // border: 1px solid transparent;
            border: 0;
            border-radius: 50%;
            background-color: transparent;

            font-size: 1rem;
            line-height: 1;

            @include hover-focus {
                text-decoration: none;
                outline: none;

            }

            &#{ $this }-av {
                padding: .5 * $btn-padding;

            }

            & span {
                display: block;
                width: 100%;
                height: 100%;

            }

            &  #{ $this }-langswitch {
                line-height:  $btn-width - 2 * $btn-padding;

            }

            &  #{ $this }-user-icon {
                background: no-repeat center center;
                background-size: 100% 100%;
                overflow: hidden;
                
                & img {
                    border-radius: 50%;
                    width: inherit;
                    height: inherit;
    
                }

            }

        }

    }
        
    & &-nav {
        &#{ $this }-nav-login {
            flex-direction: row;
            flex-basis: 100%;
            justify-content: center;    
            margin-top: .5rem;

            @include media-breakpoint-up(lg) {
                flex-basis: 0;
                margin-top: 0;
            
            }

            & .nav-link {
                padding: .1rem $navbar-nav-link-padding-x;
                border: 0;
            }

        }

    }

    & &-dropdown {
        .dropdown-menu {
            left: unset;
            right: 0;
            min-width: unset;
        
        }

    }

    //
    // Navbar themes
    //
    // Styles for navbars with dark background
    //

    &#{ $this }-dark {
        #{ $this }-brand {
            svg {
                fill: white !important;

            }
            
        } 
        
        #{ $this }-buttons {
            & button {
                &:hover {
                    background-color: #00000012;

                }

                #{ $this }-user-icon {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z' /%3E%3C/svg%3E");
                
                }

                &  #{ $this }-langswitch {
                    color: white;

                }

            }

        }

    }
  
}