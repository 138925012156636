.expl-panel {
    position: fixed;
    // top: 0;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    &_hidden {
        height: 0;
    }
    &__scrim {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        transition-property: opacity;
        transition-duration: .4s;
        opacity: .7;
        .expl-panel_hidden & {
            opacity: 0;
            height: 0;
        }
    }
    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    &__topline {
        position: fixed;
        // top: 0;
        top: 100px;
        left: 0;
        width: 100%;
        padding: 10px 0;
        color: $color-1;
        transition-property: transform;
        transition-duration: .3s;
        .expl-panel_hidden & {
            transform: translateY(-160px);
            opacity: 0;
        }
    }
    &__close {
        display: block;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: white;
        background-color: $color-1;
        svg {
            display: block;
            height: 50px;
            width: 50px;
            path {
                fill: $color-1;
                fill: white;
            }
        }
    }
    &__container {
        @extend .container;
        @extend .container-expl;
        margin-top: 70px;
        margin-bottom: $grid-gutter-width / 2;
        transition-property: transform;
        transition-duration: .3s;
        .expl-panel_hidden & {
            transform: translateY(100%);
        }
    }
    &__card {
        background-color: white;
        padding: $grid-gutter-width / 2;
        margin-left: -15px;
        margin-right: -15px;
        @include media-breakpoint-up(sm) {
            border-radius: 4px;
        }
    }
}