@import (reference) "custom-standards.less";
// Local variables
// /Local variables

.logo-primary {
    fill: @color-1 !important;
}

.logo-gray {
    fill: #575757 !important;
}

