.expl-tree {
    padding-left: 0;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: inherit;
            text-decoration: none;
            opacity: .6;
        }
    }
    &__item {
        color: $primary;
        margin-bottom: 32px;
        line-height: 1.2;
        list-style: none;
        
        ul {
           margin: 32px 0;
           padding: 0;
        }

        &_lvl-1 {
            font-size: 1.5rem;
            @include media-breakpoint-up(lg) {
                font-size: 2rem;
            }
        }
        &_lvl-2 {
            margin-left: 32px;
            font-size: 1rem;
            @include media-breakpoint-up(lg) {
                font-size: 1.5rem;
            }
        }
    }
    &__additional {
        display: block;
        margin-top: 7px;
        font-size: .9rem;
        @include media-breakpoint-up(lg) {
            font-size: 1rem;
        }
        color: $secondary !important;
        text-decoration: none !important;
        i {
            margin-left: .2em;
        }
    }
}