.review {
    &-slide {
        display: block;
        text-align: center;
        padding: 50px 0;
        @include md {
            padding: 50px 60px;
        }
       
    }
    &-img {
        display: inline-block;
        margin-bottom: 20px;
        width: 110px;
        height: 110px;
        overflow: hidden;
        img {
            border-radius: 50%;
            width: 110px;
            height: auto;
        }
    }
    &-content {
        p {
            font-style: italic;
            margin-bottom: 30px;
            color: #2b2f32;
            line-height: 28px;
            font-size: 1.1rem;
        }
        h3 {
            font-weight: $font-weight-bold;
            font-size: 21px;
            margin-bottom: 10px;
        }
        div {
            color: $secondary;
            font-weight: $font-weight-bold;
            margin-bottom: 10px;
        }
    }
}

