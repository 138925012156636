@import (reference) "custom-standards.less";
// Local variables
@container-padding: 15px; // Bootstrap gutter / 2 
// /Local variables
.container {
    &-lg { 
        padding-right: @container-padding;
        padding-left: @container-padding;
        margin-right: auto;
        margin-left: auto;
        .lg( {
            max-width: 960px;
        }
        );
        .xl( {
            max-width: 1140px;
        }
        );
    }
    &-mw-sm {
        padding-right: @container-padding;
        padding-left: @container-padding;
        margin-right: auto;
        margin-left: auto;
        .sm( {
            max-width: 576px;
        }
        );
    }
    &-mw-md {
        padding-right: @container-padding;
        padding-left: @container-padding;
        margin-right: auto;
        margin-left: auto;
        .md( {
            max-width: 768px;
        }
        );
    }
    &-mw-lg {
        padding-right: @container-padding;
        padding-left: @container-padding;
        margin-right: auto;
        margin-left: auto;
        .lg( {
            max-width: 960px;
        }
        );
    }
    // &-fluid {
    //     &_footer {
    //         background-color: #0000001a;
    //         .sm( {
    //             padding-left: 60px;
    //             padding-right: 60px;
    //         }
    //         );
    //         .xl( {
    //             padding-left: 15px;
    //             padding-right: 15px;
    //         }
    //         );
    //     }
    // }
}